import React from 'react'
import PropTypes from 'prop-types'

const links = ({ arrayOfLinks }) => {
    const constructLink = ({ link, key }) => {
        if (link.substr(0, 7).toLowerCase() === "custom:") {
            return (<li key={key}><a href={link.substr(7, (link.indexOf("+") - 7))}>{link.substr(link.indexOf("+") + 1)}</a></li>);
        }
        return (<li key={key}><a href={link}>{link}</a></li>);
    }
    if (arrayOfLinks.length <= 0) return ("");
    return (
        <ul>
            {arrayOfLinks.map((eachLink, index) => { return constructLink({ link: eachLink, key: index }) })}
        </ul>);
}

links.propTypes = {
    arrayOfLinks: PropTypes.array,
}

export default links
