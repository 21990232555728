import React from 'react';
//import { NavLink } from 'react-router-dom';
import { BrowserView, MobileView } from "react-device-detect";
import Nav from './nav';
import Footer from './footer';

import 'bootstrap/dist/css/bootstrap.min.css';

//Css for some of the styling
import './CSS/bilderAnimation.css';
import './CSS/general.css';
import './CSS/index.css';
import './CSS/scrollArrow.css';
import './CSS/kontaktaMig.css'
import FadeInOut from './components/fadeInOut';
import FadeInOutVideo from './components/fadeInOutVideo';
import AnimateText from './components/AnimateText';

function workExperience() {
    //useScript('/JavascriptFiles/jquery-3.6.0.min.js');
    // useScript('/JavascriptFiles/run.js');

    return (
        <div className="workExperience" id="root">
            <Nav></Nav>
            <div className="backgroundPicture">
                <div>
                    <BrowserView renderWithFragment="true">
                        <FadeInOutVideo count={3}></FadeInOutVideo>
                    </BrowserView>
                    <MobileView renderWithFragment="true">
                        <FadeInOut count={3}></FadeInOut>
                    </MobileView>
                </div>
                <div>
                    <AnimateText className="content">
                        <div id="Combined">
                            <div id="box1"></div>
                        </div>
                        <div id="textBild" style={{ marginTop: 0 }}>
                            <h1 id="title">Experience</h1>
                        </div>
                    </AnimateText>
                    <a className="ca3-scroll-down-link ca3-scroll-down-arrow" id="button1" data-ca3_iconfont="ETmodules"
                        data-ca3_icon="" data-scroll href="#text" aria-label="Scroll down"></a>
                </div>
            </div>
            <div id="container">

                <div id="middleCont">

                    <div id="textField" style={{ marginTop: "2%" }}>

                        <section>

                            <div id="text">
                                <h1><b><i>Education</i></b></h1>
                                <hr></hr><br></br>
                                <br></br>
                                <h2><b>School</b></h2>
                                <p>Upper secondary school, Tekniskt program, Westerlundska gymnasiet [Ht 17 - Vt 20]</p>
                                <br></br>
                                <h2><b>Interesting courses I have finished</b></h2>
                                <ul>
                                    <a href="https://bit.ly/32e3INc"><li>Two courses in the C# programming language, basic and advance.</li></a>
                                    <a href="https://bit.ly/3a6WRt4"><li>Basic web-development course</li></a>
                                    <a href="https://bit.ly/3a8SiyF"><li>Course in 3D modeling using 3D Studio Max</li></a>
                                    <a href="https://bit.ly/2QgUcpH"><li>Course in Network Technology</li></a>
                                    <a href="https://bit.ly/3g9cHHm"><li>Course in Computer and Network Technology</li></a>
                                    <a href="https://bit.ly/3wUVI1G"><li>Course in Electronics and Microcomputer Technology</li></a>
                                    <a href="https://bit.ly/3gdRP1M"><li>Course in advanced English (Engelska 7)</li></a>
                                </ul>

                                <br></br>
                                <h2><b>Courses I have finished</b></h2>
                                <ul>
                                    <a href="/files/D0017D.pdf"><li>7.5hp Luleå technical university - Course in the Java programming language [VT 21] Betygskriterier IGK/GK, betyg GK</li></a>
                                    <a href="/files/NicholasObucina-Sum21 CCNA Cyber-certificate.pdf"><li>15hp Högskolan i Skövde - Introduktion till cybersäkerhet G1N [HT 21] Betygskriterier F-A, betyg A</li></a>
                                </ul>
                                <img style={{maxWidth: "96px", maxHeight: "96px"}}src="./certificates/cyberops-associate.png" alt="CyberOps Associate Badge"></img>
                                <br></br>

                                <h2><b>Courses/programs I am currently participating in [HT 21]</b></h2>
                                <ul>
                                    <a href="https://www.mdh.se/utbildning/program/datavetenskapliga-programmet"><li>Mälardalens Högskola - Data­vetenskapliga programmet</li></a>
                                </ul>
                            </div>

                        </section>

                        <div style={{ paddingTop: "50px", backgroundColor: "gainsboro" }}>

                            <h1 id="workPlaces" >Work places</h1>
                            <hr></hr>
                            <section>
                                <div id="section2">

                                    <div className="workexperience">
                                        <img src="./workPlace_Images/ericsson.jpg" alt="Not available."></img>
                                        <h1>LM Ericsson</h1>
                                        <p id="BigFirstLetter">I started my journey at Ericsson when I first applied for an internship through Tekniksprånget. Ericsson was a gratifying and educative experience as I learned a bunch from the time working there. I was a part of the Radio Energy Performance team that focuses on making the products more efficient and overall reducing power consumption in Ericsson's radio products. </p>
                                        <p>My role was primarily to create calculations, Excel sheets, Word Documents, PowerPoint presentations, and having meetings with people around the world. Additionally, I was also responsible for ordering and retrieving radios that would be tested and see if they operate to expectation. To make the calculations more efficient, I took the initiative to automate much of the tasks through Excel and the programming language VBA. </p>
                                        <a href="/files/ERICSSON.pdf">Click here to read employer certificate</a>
                                    </div>


                                    <div className="workexperience">
                                        <img src="./workPlace_Images/alma_assistans.png" alt="Not available."></img>
                                        <h1>Alma Assistans</h1>
                                        <p id="BigFirstLetter">From the year 2019, I have occasionally worked as a personal assistant for a boy with severe autism. The employer certificate (can be viewed below) ended in mid-2020 as I started working full time at Ericsson. At the moment, I am still working occasionally on weekends.</p>
                                        <a href="/files/ALMA_ASSISTANS.pdf">Click here to read employer certificate</a>
                                    </div>

                                </div>

                                <div id="section2">

                                    <div className="workexperience">
                                        <img src="./workPlace_Images/postnord.jpg" alt="Not available."></img>
                                        <h1>PostNord</h1>
                                        <p id="BigFirstLetter"></p>
                                        <a href="/files/PostNord.pdf">Click here to read employer certificate</a>
                                    </div>

                                </div>
                            </section>
                        </div>



                    </div>




                </div>
            </div>
            <Footer></Footer>
        </div >

    );
}

export default workExperience;