import React from 'react';
import Checkbox from './checkBox';
import PropTypes from 'prop-types';


const checkBoxes = ({ arrayOfNames, onPress }) => {
    return (
        <section id="filterOutput">
            {arrayOfNames.map((eachItem, index) => {
                return (<Checkbox key={index} text={eachItem} onPress={onPress}></Checkbox>)
            })}
        </section>
    )
}

checkBoxes.propTypes = {
    arrayOfNames: PropTypes.array,
}

export default checkBoxes
