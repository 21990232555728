import React from 'react'
import PropTypes from 'prop-types'
import { decode } from 'html-entities';

const bodyTexts = ({ arrayOfTexts }) => {
    const constructText = ({ text, key }) => {
        return (<p key={key}>{decode(text)}</p>);
    }
    if (arrayOfTexts.length <= 0) return ("");
    return (
        <React.Fragment>
            {arrayOfTexts.map((eachText, index) => { return constructText({ text: eachText, key: index }) })}
        </React.Fragment>
    );
}

bodyTexts.propTypes = {
    arrayOfTexts: PropTypes.array,
}

export default bodyTexts
