import React, { useState, useEffect } from 'react';
import Nav from './nav';
import Footer from './footer';

// import Home from './home/Home';
// import Register from './register/Register';
import * as Env from "./environments";
import 'bootstrap/dist/css/bootstrap.min.css';
import './CSS/MyProjects.css'
import Parse from 'parse';
//import API from './components/API';
import ListOfItems from './components/listOfItems';
//import Item from './components/Item';
import Images from './components/images';
import Links from './components/links';
import Checkboxes from './components/checkBoxes';
import BodyTexts from './components/bodyTexts';

//Css for some of the styling
import './CSS/bilderAnimation.css';
import './CSS/general.css';
import './CSS/index.css';
import './CSS/scrollArrow.css';

//Javascript for some of the functions


Parse.initialize(Env.APPLICATION_ID, Env.JAVASCRIPT_KEY);
Parse.serverURL = Env.SERVER_URL;

function MyProjects() {
  // useScript('/JavascriptFiles/jquery-3.6.0.min.js');
  // useScript('/JavascriptFiles/run.js');

  //The following array should remain constant and not be changed once innitilized. It holds all the projects that are available and therefor lowers the any additional api requests.
  const [constantArray, setConstantArray] = useState([]);

  //The following array will be used to display the choices for the user. This array will be updated depending on the checkboxes the user can select from.
  const [List, setList] = useState([]);

  //The title string that represents the title.
  const [Title, setTitle] = useState("");
  //The preamble string that represents the preamble.
  const [Preamble, setPreamble] = useState("");
  //The body string that represents the body content.
  const [BodyText, setBodyText] = useState([]);
  //The string that displays each programming language that has been involved with the project.
  const [Programming_Languages, setProgramming_Languages] = useState("");
  //Array of strings that will be displayed as clickable links.
  const [LinksToStudies, setLinksToStudies] = useState([]);

  //The following array will include a unique list of all programming languages involved in all projects.
  const [ListOfAllProgramming_Languages, setListOfAllProgramming_Languages] = useState([]);
  //The following array will be used to represent he selected programming languages the user has selected through the checkboxes.
  //If the user hasen't selected anything, then the this array will be innitilized to the ListOfAllProgramming_Languages array.
  const [arrayOfProgrammingLanguages, setArrayOfProgrammingLanguages] = useState([]);


  //A boolean value that will check if the project has any images, if true then load the images.
  const [HasImages, setHasImages] = useState(false);
  //List that will include the images for the chosen project.
  const [ListOfImages, setListOfImages] = useState([]);


  //The first action the program  will do is fetching from the back4app api that i have set up.
  useEffect(() => {
    //The following code fetches from the back4app api that i use to store all my projects i have done.

    //Using the "projects" custom class that I have made.
    const projects = Parse.Object.extend('projects');
    const query = new Parse.Query(projects);

    //Uses the returned promise and uses it to initilize multiple different values. 
    query.find().then((results) => {
      setConstantArray(results);

      setList(results);
      //Innitilizing the 
      asignValuesToMembers(results[0]);
      appendIfNotInArray(results);

      //Lets assign the first to the existing values
    }, (error) => {
      if (typeof document !== 'undefined') document.write(`Error while fetching projects: ${JSON.stringify(error)}`);
      console.error('Error while fetching projects', error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  //the following function will iterate through all the projects in the passed array and crate a unique list of all the programming languages/tags that exists.
  const appendIfNotInArray = (array) => {
    let arrayThatWillBeReturned = [];
    //iterate through all the projects
    for (var item in array) {
      //Checks so the project has programming languages specified (so it dosen't crash)
      if (array[item]['attributes']['Programming_languages'] !== undefined) {
        //Iterating through each programming language.
        for (var eachLanguage in array[item]['attributes']['Programming_languages']) {
          //Makes the string lowercase so it can be identified as unique when checking the array.
          let appendThisValueToArray = array[item]['attributes']['Programming_languages'][eachLanguage].toLowerCase();
          //Checks so the specific programming langauge dosen't already exist in the array, if it dosen't then add it!
          if (ListOfAllProgramming_Languages.includes(appendThisValueToArray) === false) {
            arrayThatWillBeReturned = [...arrayThatWillBeReturned, appendThisValueToArray];
            //setListOfAllProgramming_Languages(ListOfAllProgramming_Languages => [...new Set([...ListOfAllProgramming_Languages, appendThisValueToArray])]);
          }
        }
      }
    }
    setListOfAllProgramming_Languages([...new Set(arrayThatWillBeReturned)]);
  }

  //The following function will get a specific project using its unique ID.
  const fetchFromAPIwidthID = (Id) => {
    return List.filter((checkState) => { return (checkState['id'] === Id) ? checkState : "" })[0]
  };

  //The following function will be used to assign the values when a project has been selected.
  const asignValuesToMembers = (itemToUse) => {
    //Asign the title, preamble and the body text.
    setTitle(itemToUse['attributes']['title']);
    setPreamble(itemToUse['attributes']['preamble']);
    //If the texts for the body is specified in this project, then add them.
    if (itemToUse['attributes']['body_array'] !== undefined) {
      setBodyText(itemToUse['attributes']['body_array']);
    }
    else {
      setBodyText([]);
    }


    //If there are programming languages specified in this project, then create a unified
    if (itemToUse['attributes']['Programming_languages'] !== undefined) {
      setProgramming_Languages("Areas covered: [" + itemToUse['attributes']['Programming_languages'].join(', ') + "]");
    }
    else {
      setProgramming_Languages("");
    }
    //If there are links specified in this project, then add them.
    if (itemToUse['attributes']['links'] !== undefined) {
      setLinksToStudies(itemToUse['attributes']['links']);
    }
    else {
      setLinksToStudies([]);
    }


    //If there are any images available for this project, then set the HasImages boolean to true and add the images to the ListOfImages array.
    if (itemToUse['attributes']['IMAGE1'] !== undefined || itemToUse['attributes']['IMAGE2'] !== undefined || itemToUse['attributes']['IMAGE3'] !== undefined) {
      let arrayOfPictures = [
        (itemToUse['attributes']['IMAGE1'] !== undefined) ? itemToUse['attributes']['IMAGE1']['_url'] : "",
        (itemToUse['attributes']['IMAGE2'] !== undefined) ? itemToUse['attributes']['IMAGE2']['_url'] : "",
        (itemToUse['attributes']['IMAGE3'] !== undefined) ? itemToUse['attributes']['IMAGE3']['_url'] : ""
      ]
      setHasImages(true);
      setListOfImages(arrayOfPictures);
    }
    else {
      setHasImages(false);
    }


  }

  //The following function will sort the project list according to what the user has selected in the checkboxes.
  const sortByCheckboxes = (id) => {
    //Copy of the array of programming languages the user has specified.
    let arrayToUse = arrayOfProgrammingLanguages;

    //If the checkbox is checked then append the rule to the array of programming langauges.
    if (document.getElementById(id).checked) {
      arrayToUse = [...arrayOfProgrammingLanguages, id];
    }
    else {
      //If the checkbox is unchecked then remove the critieria from the array instead.
      arrayToUse = arrayOfProgrammingLanguages.filter((item) => {
        return (item !== id)
      });
    }


    //Are there any checkboxes checked, then do the following.
    if (arrayToUse.length > 0) {
      //Creating a temporary array that we will use as a array of projects filtered accordingly to the criterias.
      let filteredArray = [];
      //Iterating all the projects that we have stored in the constantArray (which will not change).
      for (var eachItemInArray in constantArray) {
        //Iterating through all the programming languages we want to only want filtered as.
        for (var itemInArray in arrayToUse) {
          //Does the project have programming languages initilized in the first place?
          if (constantArray[eachItemInArray]['attributes']['Programming_languages'] !== undefined) {
            //Lets create a copy of the list of programming languages and turn it to lowercase so we can identify so if it is unique or not (if it already exists in the array)
            let toSmallerArray = constantArray[eachItemInArray]['attributes']['Programming_languages'].map(item => item.toLowerCase());
            //Does the project include a keyword? Then give it the green light and add it to the filtered array.
            if (toSmallerArray.includes(arrayToUse[itemInArray])) {
              filteredArray = [...filteredArray, constantArray[eachItemInArray]];
            }
          }
        }
      }
      //Finnaly, update the list based on the new values.
      setList([...new Set(filteredArray)]);
    } else {
      //As no criterias has ben specified (no checkboxes has been checked), set list of projects to the costantArray (all projects available).
      setList(constantArray);
    }

    setArrayOfProgrammingLanguages(arrayToUse);
  }




  //If the user clicks a project in the list, then assign the projects respective values to the right container.
  const onClick = (id) => {
    let valuesToUse = fetchFromAPIwidthID(id);
    asignValuesToMembers(valuesToUse);
  }

  return (
    <div className="MyProjects" id="root">
      <Nav></Nav>
      <h4 style={{ alignSelf: "center", textDecoration: "underline", color: "black" }}>Last updated: 2021</h4>
      <div id="container" style={{ padding: "1%" }}>
        <div id="left-container">
          <Checkboxes arrayOfNames={ListOfAllProgramming_Languages} onPress={sortByCheckboxes}></Checkboxes>
          <ListOfItems Items={List} onClick={onClick} />
        </div>
        <div id="right-container">
          <div id="upperLayer">
            <h1 className={`title-Text ${(Title === "") ? 'displayBlockObject' : ''}`}>{Title}</h1>
            <p className={`tags-Text ${(Programming_Languages === "") ? 'displayBlockObject' : ''}`}>{Programming_Languages}</p>
          </div>
          <div id="allText">
            <p className={`preamble-Text ${(Preamble === "") ? 'displayBlockObject' : ''}`}><i><b>{Preamble}</b></i></p>
            <div className={`body-Text ${(BodyText.length <= 0) ? 'displayBlockObject' : ''}`}>
              <BodyTexts arrayOfTexts={BodyText}></BodyTexts>
            </div>
          </div>
          <div className={`imagesOfProject ${(HasImages) ? '' : 'displayBlockObject'}`}>
            <Images arrayOfImages={ListOfImages} hasImages={HasImages}></Images>
          </div>
          <div className={`links-Text ${(LinksToStudies.length <= 0) ? 'displayBlockObject' : ''}`}>
            <Links arrayOfLinks={LinksToStudies}></Links>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default MyProjects;
