import React from 'react'
import PropTypes from 'prop-types'

const checkBox = ({ text, onPress }) => {

    return (
        <div>
            <input type="checkbox" id={text} name={text} value={text} onClick={() => {
                onPress({ text }['text'])
            }}></input>
            <label htmlFor={text}>{text}</label>
        </div >
    )
}

checkBox.propTypes = {
    text: PropTypes.string,
}

export default checkBox
