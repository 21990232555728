import React from 'react';
import { NavLink } from 'react-router-dom';


//Css for some of the styling
import './CSS/general.css';
import './CSS/index.css';

function Nav() {

    
  const navSlide = () => {
    const burger = document.querySelector('.burger');
    const nav = document.querySelector('#top-menu');
    const navLinks = document.querySelectorAll('#top-menu li')
  
    nav.classList.toggle('nav-active');
  
  
    navLinks.forEach((link, index) => {
        if (link.style.animation) {
            link.style.animation = '';
        }
        else {
            link.style.animation = `navLinkFade 0.5s ease forwards ${index / 5 + 0.5}s`;
        }
    });
  
    burger.classList.toggle('toggle');
  
  
  }


  return (
    <div className="Nav">
        <div id="wrapper">
            <nav id="nav">
                <a className="active" href="/" style={{ textDecoration: 'none' }}>
                    <div id="textNav">
                        <img src="images/logo/white_logo.svg" alt="Not available."></img>
                        <p><b>CV</b></p>
                    </div>
                </a>
                <ul id="top-menu">
                    <li><NavLink to="/projects">Projects</NavLink></li>
                    <li><a href="https://coderbyte.com/profile/nicho015">Coderbyte</a></li>
                    <li><a href="https://www.linkedin.com/in/nicholas-sj%C3%B6qvist-obucina-92373b315/">LinkedIn</a></li>
                    <li><NavLink to="/experience">Experience</NavLink></li>
                    <li><NavLink to="/contact">Contact Me</NavLink></li>
                </ul>

                <div>
                    <div className="burger" onClick={navSlide}>
                        <div className="line1"></div>
                        <div className="line2"></div>
                        <div className="line3"></div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
  );
}

export default Nav;
