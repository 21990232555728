import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';


import ReactDOM from 'react-dom';
import './CSS/index.css';

import Hem from './Hem';
import Experience from './Experience';
import Contact from './Contact';
import Error from './error';
import MyProjects from './MyProjects';

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <Switch>
        <Route path="/" component={Hem} exact />
        <Route path="/projects" component={MyProjects} />
        <Route path="/experience" component={Experience} />
        <Route path="/contact" component={Contact} />
        <Route component={Error} />
      </Switch>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);
