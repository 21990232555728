import React from 'react';

//Css for some of the styling
import './CSS/general.css';
import './CSS/index.css';

function Footer() {

  return (
    <div className="Footer">
        <footer>
            <div className="vansterInfo"></div>
            <img src="images/logo/white_logo.svg" id="whitelogo" alt="Not available."></img>
            <div id="collection">
                <p><a href="tel:+46-076-196-2415" style={{ color: "white" }}>076-196 2415</a></p>
                <p><a href="mailto:nicholas.obucina@hotmail.com" style={{ color: "white" }}>nicholas.obucina@hotmail.com</a></p>
            </div>
        </footer>
    </div>
  );
}

export default Footer;
