import React from 'react';

import { BrowserView, MobileView } from "react-device-detect";
import Nav from './nav';
import Footer from './footer';

import 'bootstrap/dist/css/bootstrap.min.css';

//Css for some of the styling
import './CSS/bilderAnimation.css';
import './CSS/general.css';
import './CSS/index.css';
import './CSS/scrollArrow.css';
import './CSS/kontaktaMig.css';
import FadeInOut from './components/fadeInOut';
import FadeInOutVideo from './components/fadeInOutVideo';
import AnimateText from './components/AnimateText';

function Contact() {
    //useScript('/JavascriptFiles/jquery-3.6.0.min.js');
    // useScript('/JavascriptFiles/run.js');

    return (
        <div className="Contact" id="root">
            <Nav></Nav>
            <div className="backgroundPicture">
                <div>
                    <BrowserView renderWithFragment="true">
                        <FadeInOutVideo count={3}></FadeInOutVideo>
                    </BrowserView>
                    <MobileView renderWithFragment="true">
                        <FadeInOut count={3}></FadeInOut>
                    </MobileView>
                </div>
                <div>
                    <AnimateText className="content">
                        <div id="Combined">
                            <div id="box1"></div>
                        </div>
                        <div id="textBild" style={{ marginTop: 0 }}>
                            <h1 id="title">Contact Information</h1>
                        </div>
                    </AnimateText>
                    <a className="ca3-scroll-down-link ca3-scroll-down-arrow" id="button1" data-ca3_iconfont="ETmodules"
                        data-ca3_icon="" data-scroll href="#text" aria-label="Scroll down"></a>
                </div>
            </div>
            <div id="container">

                <div id="middleCont">

                    <div id="textField" style={{ marginTop: "2%" }}>

                        <section>
                            <div id="text">
                                <h1><b><i>Contact Information</i></b></h1>
                                <hr></hr><br></br>
                                <h2><b>Nicholas Sjöqvist Obucina</b></h2>
                                <p>Telephone: <a href="tel:+46-076-196-2415">076-196 2415</a></p>
                                <p>Email: <a href="mailto:nicholas.obucina@hotmail.com">nicholas.obucina@hotmail.com</a></p>
                                <p>Linkedin: <a href="https://se.linkedin.com/in/nicholas-sj%C3%B6qvist-obucina-92373b315">https://se.linkedin.com/in/nicholas-sj%C3%B6qvist-obucina-92373b315</a></p>
                                <br></br>
                                <p>Please, do not hesitate to contact me.</p>
                            </div>

                        </section>

                    </div>

                </div>
            </div>
            <Footer></Footer>
        </div >

    );
}

export default Contact;