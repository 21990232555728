import React from 'react';
import PropTypes from 'prop-types'

const Item = ({ name, onClick }) => {
    return (<li onClick={onClick}>{name}</li >);
}

Item.defaultProp = {
    name: "default",
}

Item.propTypes = {
    name: PropTypes.string,
}

export default Item
