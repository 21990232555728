import React from 'react';

const error = () => {
    return (
        <div>
            <p>Error: The page you are trying to access does not exist!</p>
        </div>
    );
}

export default error;